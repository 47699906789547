import {
    ApplicationConfig,
    importProvidersFrom,
    isDevMode
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire/compat';

import { provideTransloco } from '@jsverse/transloco';

import { TranslocoHttpLoader } from './transloco-loader';
import { routes } from './app.routes';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideClientHydration(),
        provideAnimationsAsync(),
        provideHttpClient(withFetch()),
        provideHttpClient(),
        provideTransloco({
            config: {
                availableLangs: [
                    'en',
                    'de',
                    'hr',
                    'zh',
                    'es',
                    'fr',
                    'ja',
                    'pt',
                    'ru',
                    'vi',
                    'cs'
                ],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode()
            },
            loader: TranslocoHttpLoader
        }),
        importProvidersFrom(
            AngularFireModule.initializeApp(environment.firebase)
        ),
        provideStorage(() => getStorage())
    ]
};
